<template>
  <div>
    <h2 class="text-dark font-bold min-fsize40 sm-fsize22px">Economist Podcasts</h2>

    <!-- tabs -->

    <div class="lg:mt-10 md:mt-10 mt-1">
          <EconomistPodcastsSwiper :GroupList="GroupList" />
          <InvestorTherapy />
      <!-- <vs-tabs class="tabs-spacing">
        <vs-tab label="Investor Therapy"><InvestorTherapy /> </vs-tab>

        <vs-tab label="International Markets"> </vs-tab>

        <vs-tab label="Indian Market"> </vs-tab>

        <vs-tab label="Kotak Katha"> </vs-tab>

        <vs-tab label="CSR"> </vs-tab>
      </vs-tabs> -->
    </div>
  </div>
</template>

<script>
import InvestorTherapy from "./components/InvestorTherapy";

import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
import jwt from "jsonwebtoken";

const groupsRepository = RepositoryFactory.get("groups");
const postsRepository = RepositoryFactory.get("posts");
import EconomistPodcastsSwiper from "./components/EconomistPodcastsSwiper.vue";
export default {
  data() {
    return {
      GroupList: [],
      token: null,
      groupPosts: [],
      id: null,
      postsLimit: 30,
      postsOffset: 0,
    };
  },
  components: {
    InvestorTherapy,
    EconomistPodcastsSwiper,
  },
  methods: {
    async getGroupList() {
      var self = this;
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupData: {
          userId: self.token.userID,
        },
      };
      const { data } = await groupsRepository.getGroupsOfUser(obj);
      if (data) {
        data.forEach((element) => {
          element.isUserGroup = true;
          this.GroupList.push(element);
        });
        // self.GroupList=data;
        this.getPublicGroupList();
      }
    },
    async getPublicGroupList() {
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupData: {
          userId: this.token.userID,
        },
      };
      const { data } = await groupsRepository.getPublicGroups(obj);

      if (data) {
        data.forEach((element) => {
          element.isPublicGroup = true;
          this.GroupList.push(element);
        });
        // this.getPrivateGroupList();
        // self.GroupList=data;
      }
    },
    async getPrivateGroupList() {
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupData: {
          userId: this.token.userID,
        },
      };
      const { data } = await groupsRepository.getPrivateGroups(obj);

      if (data) {
        data.forEach((element) => {
          element.isPrivateGroup = true;
          this.GroupList.push(element);
        });
        // self.GroupList=data;
      }
    },
    async getPostsOfGroup(id) {
      let obj;
      if (id != "") {
        obj = {
          userData: {
            id: this.token.userID,
            uuid: this.token.uuid,
          },
          groupData: {
            id: id,
            // id: this.$store.state.groupId,
          },
          communityData: {
            communityName: currentCommunityName.communityName,
          },
          paging: {
            limit: this.postsLimit,
            offset: this.postsOffset,
          },
        };
      } else {
        obj = {
          userData: {
            id: this.token.userID,
            uuid: this.token.uuid,
          },
          groupData: {
            id: this.GroupList[0].groupId,
            // id: this.$store.state.groupId,
          },
          communityData: {
            communityName: currentCommunityName.communityName,
          },
          paging: {
            limit: this.postsLimit,
            offset: this.postsOffset,
          },
        };
      }

      const { data } = await postsRepository.getPostsOfGroup(obj);
      await this.$store.commit("post/homeChannelPodcard", data);
      data.map((x) => {
        if (x.image) {
          this.imagePost.push(x);
        }
      });
      // return new Promise((resolve) => {
      //   if (data) {
      //     this.postsPage += 1;
      //     this.postsOffset += this.postsLimit;

      //     if (data.length > 0) {
      //       data.forEach((element) => {
      //         // if (
      //         //   moment().diff(
      //         //     moment(element.post_group_mapping_created_datetimestamp),
      //         //     "hours"
      //         //   ) < 13
      //         // ) {
      //         // element.post_group_mapping_created_datetimestamp = moment(
      //         //   element.post_group_mapping_created_datetimestamp
      //         // ).fromNow();
      //         // } else {
      //         //   element.post_group_mapping_created_datetimestamp = moment(
      //         //     element.post_group_mapping_created_datetimestamp
      //         //   ).format("DD-MM-YYYY HH:mm");
      //         // }
      //         if (element.image != null) {
      //           if (element.image.includes("uploads/postImages")) {
      //             element.image = this.magittUrl + element.image;
      //           }
      //         }
      //         if (element.video_url != null) {
      //           if (element.video_url.includes("uploads/postVideos")) {
      //             element.video_url = this.magittUrl + element.video_url;
      //           }
      //         }
      //         if (element.audio_url != null) {
      //           if (element.audio_url.includes("uploads/postAudios")) {
      //             element.audio_url = this.magittUrl + element.audio_url;
      //           }
      //         }
      //         if (element.featured_image != null) {
      //           if (element.featured_image.includes("uploads/postImages")) {
      //             element.featured_image =
      //               this.magittUrl + element.featured_image;
      //           }
      //         }
      //         if (element.PollQuestion !== null) {
      //           element.VotedIds =
      //             element.VotedIds !== null ? element.VotedIds : "";
      //           element.AnswersIds =
      //             element.AnswersIds !== null
      //               ? element.AnswersIds.split(",")
      //               : [];
      //           element.PollAnswers =
      //             element.PollAnswers !== null
      //               ? element.PollAnswers.split(",")
      //               : [];
      //           element.AnswersVotes =
      //             element.AnswersVotes !== null
      //               ? element.AnswersVotes.split(",")
      //               : [];
      //         }
      //         element.comments = [];
      //         element.newComment = "";
      //         element.showReply = false;
      //         element.first_comment_replies = [];
      //         element.isLikeLoading = false;
      //         this.groupPosts.push(element);
      //       });

      //       resolve(true);
      //     } else {
      //       //       ////debugger
      //       resolve(false);
      //     }
      //   } else {
      //     resolve(false);
      //   }
      // });
    },
  },
  mounted() {
    this.token = jwt.decode(localStorage.getItem("token"));
    this.getGroupList();
    // this.getPostsOfGroup();
    // this.getGroupDetails();
  },
};
</script>
