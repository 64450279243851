<template>
  <div class="relative">
    <img
      src="@/Core/assets/images/home/homebanner.png"
      alt="image"
      class="w-full banner-h500px sm-banner350px object-cover"
    />

    <div
      class="absolute h-full items-center lg:top-12 top-2 top-0 left-0 lg:py-16 md:py-10 py-5 left-0 lg:px-24 md:px-24 px-5 w-full lg:flex md:flex block"
    >
      <div class="lg:w-3/5 md:w-8/12 w-full lg:pt-0 md:pt-0 pt-5">
        <div class="flex items-center">
          <span
            class="cursor-pointer font-semibold text-white cursor-pointer sm-fsize12px"
            >Kotak Treasury
          </span>
          <feather-icon
            svgClasses="w-5 h-5"
            icon="ChevronRightIcon"
            class="text-danger mx-1"
          />
          <span class="font-semibold text-white sm-fsize12px">Microsite</span>
        </div>
        <h2 class="font-bold min-fsize40 text-white py-4 sm-fsize22px">
          Market Updates & Insights <br />
          from our Economists
        </h2>

        <p class="text-white lg:w-1/2 sm-fsize14px">
          Find blogs, podcasts, playlists, videos & guides to help you learn
          more about fitness & well-being.
        </p>

        <div class="block">
          <div class="flex justify-start md:mt-8 lg:mt-8 mt-4">
            <vs-button
              color="primary"
              class="font-semibold uppercase rounded-none lg:py-4 sm-btn-size btn-padding"
              v-if="isLogin == false"
              @click="readerLogin"
            >
              GET STARTED
            </vs-button>
            <!-- <vs-button
              class="uppercase rounded-none lg:py-4 font-semibold sm-btn-size btn-padding"
              type="border"
              color="light"
            >
              EXPLORE KOTAK PRODUCTS
            </vs-button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      centerDialogVisible55: false,
    };
  },
  computed: {
    isLogin() {
      if (localStorage.getItem("token")) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    readerLogin() {
      this.$router.push("/pages/kotaklogin").catch(() => { });
    }
  }
};
</script>
