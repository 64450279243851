<template>
  <div>
    <HomeBanner />

    <div class="lg:py-16 md:py-10 py-8 lg:px-24 md:px-24 px-5">
      <LatestUpdateCore />
    </div>

    <div class="">
      <DealBanner />
    </div>

    <div class="lg:py-16 md:py-10 py-8 lg:px-24 md:px-24 px-5">
      <EconomistPodcasts />
    </div>

    <div class="">
      <SubscribeBanner />
    </div>

    <!-- <div class="lg:py-16 md:py-10 py-8 lg:px-24 md:px-24 px-5">
      <HomeProducts />
    </div> -->
  </div>
</template>
<script>
import HomeProducts from "./components/HomeProducts";
import EconomistPodcasts from "./components/economistPodcasts/EconomistPodcasts.vue";
import DealBanner from "./components/DealBanner";
import HomeBanner from "./components/HomeBanner";
import SubscribeBanner from "./components/SubscribeBanner";
import LatestUpdateCore from "./components/latestUpdateCore/LatestUpdateCore";

export default {
  data() {},
  components: {
    HomeProducts,
    HomeBanner,
    LatestUpdateCore,
    DealBanner,
    EconomistPodcasts,
    SubscribeBanner,
  },
};
</script>
