<template>
  <div class="relative">
    <img
      src="@/Core/assets/images/home/dealbanner.png"
      alt="image"
      class="w-full bannerdeal-height sm-bannerdeal-height object-cover"
    />

    <div
      class="absolute h-full items-center lg:top-12 top-2 top-0 left-0 lg:py-16 md:py-10 py-5 left-0 lg:px-24 md:px-24 px-5 w-full lg:flex md:flex block"
    >
      <div class="lg:w-3/5 md:w-8/12 w-full lg:pt-0 md:pt-0 pt-5">
        <span class="cursor-pointer font-semibold text-white cursor-pointer"
          >Kotak fX deals
        </span>

        <h2 class="font-bold min-fsize40 text-white py-4 sm-fsize22px">
          One calendar for all your <br />
          investment needs
        </h2>

        <div class="mt-4">
          <vs-button
            color="dark"
            class="font-semibold uppercase rounded-none mr-6 lg:py-4 sm-btn-size"
            @click="openCalender"
          >
            EXPLORE NOW
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      centerDialogVisible55: false,
    };
  },
  methods: {
    openCalender() {
      this.$router.push("/calendar/fx").catch(() => {});
    },
  },
  computed: {
    magittUrl() {
      return this.baseUrlMagitt;
    },
  },
};
</script>
