<template>
  <div class="lg:mt-4 md:mt-4">
    <div class="lg:grid md:grid lg:grid-cols-3 md:grid-cols-3 gap-8">
     
      <card
        v-for="(item, index) in homeChannelPost.Data"
        :key="'item-' + index"
        class="cursor-pointer"
      >
        <div @click="handleBlogDetails(item)">
          <div class="latest-img-h w-full">
            <img
              v-if="item.featured_image != null"
              :src="magittUrl + item.featured_image"
              @error="
                item.featured_image =
                  magittUrl + 'uploads/defaultImages/default_post.png'
              "
              alt="img"
              class="w-full latest-img-h object-cover"
            />
            <img
              v-else
              :src="magittUrl + 'uploads/defaultImages/default_post.png'"
              alt="img"
              class="w-full latest-img-h object-cover"
            />
          </div>

          <div class="p-4 bg-f2f2f2 lg:mb-0 md:mb-0 mb-3">
            <h4
              class="text-3d3d3d font-semibold min-fsize22 sm-fsize16px"
              v-html="item.text.substring(0, 20)"
            ></h4>

            <div class="flex justify-between items-center mt-4">
              <p class="text-9e9e9e font-semibold sm-fsize10px">
                {{ item.Created_by }}
              </p>
              <p class="text-9e9e9e font-semibold sm-fsize10px">
                {{ new Date(item.createdAt).toLocaleString() }}
              </p>
            </div>
          </div>
        </div>
      </card>
    </div>

    <div class="mt-12" @click="getPostByCategory('pagination')">
      <vs-pagination
        v-model="currentPage"
        :total="Math.ceil(totalPageBlog)"
        :current-page="currentPage"
      ></vs-pagination>
    </div>
  </div>
</template>
<script>
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
import jwt from "jsonwebtoken";
import { mapState } from "vuex";

// const groupsRepository = RepositoryFactory.get("groups");
const postsRepository = RepositoryFactory.get("posts");

export default {
  props: {
    id: { type: Number, required: true },
  },
  data() {
    return {
      currentx: 2,
      imagePost: [],
      token: null,
      groupPage: 1,
      groupSize: 10,
      perPage: 6,
      totalPages: 1,
      currentPage: 1,
      policyCards: [
        {
          id: "01",
          img: require("@/Core/assets/images/home/latest/b1.webp"),
          title: "RBI might hike rate by 50 bps, recession ‘very unlikely’",
          name: "Ajay  Verma ",
          date: "9th September, 2022",
        },
        {
          id: "02",
          img: require("@/Core/assets/images/home/latest/b2.webp"),
          title: "RBI might hike rate by 50 bps, recession ‘very unlikely’",
          name: "Ajay  Verma ",
          date: "9th September, 2022",
        },
        {
          id: "03",
          img: require("@/Core/assets/images/home/latest/b4.webp"),
          title: "RBI might hike rate by 50 bps, recession ‘very unlikely’",
          name: "Ajay  Verma ",
          date: "9th September, 2022",
        },

        {
          id: "04",
          img: require("@/Core/assets/images/home/latest/b5.webp"),
          title: "RBI might hike rate by 50 bps, recession ‘very unlikely’",
          name: "Ajay  Verma ",
          date: "9th September, 2022",
        },
        {
          id: "05",
          img: require("@/Core/assets/images/home/latest/b6.webp"),
          title: "RBI might hike rate by 50 bps, recession ‘very unlikely’",
          name: "Ajay  Verma ",
          date: "9th September, 2022",
        },
        {
          id: "06",
          img: require("@/Core/assets/images/home/latest/b7.webp"),
          title: "RBI might hike rate by 50 bps, recession ‘very unlikely’",
          name: "Ajay  Verma ",
          date: "9th September, 2022",
        },
      ],
    };
  },
  methods: {
    handleBlogDetails(postData) {
      // console.log(postData);
      // if(localStorage.getItem("token")){
      // console.log(postData);
      let singlePostLink = `${window.document.location.origin}/readers-view?pid=${postData.post_UniqueId}`;
      // window.open(singlePostLink, "_blank");

      let payload = {
        full: singlePostLink,
      };

      this.$vs.loading();

      this.$store
        .dispatch("shortURL/addShortUrl", payload)
        .then(() => {
          this.$vs.loading.close();
          // console.log(this.$store.state.shortURL.url.full);
          window.open(this.$store.state.shortURL.url.full, "_blank");
        })
        .catch(() => {
          this.$vs.loading.close();
        });
      // }
      // else{
      //   this.$router.push("/pages/customadminlogin").catch(() => {});}
    },
    async getPostByCategory(item) {
      // console.log(this.categorySlider);
      this.$vs.loading();
      let obj;
      if (item) {
        // console.log(this.objCategory);
        if (this.objCategory.type == "category") {
          obj = {
            communityData: {
              communityName: currentCommunityName.communityName,
            },
            Data: {
              type: "blogs",
              limit: this.perPage,
              offset: (this.currentPage - 1) * this.perPage,
              category: this.objCategory.title,
            },
          };
        }
        if (this.objCategory.type == "author") {
          obj = {
            communityData: {
              communityName: currentCommunityName.communityName,
            },
            Data: {
              type: "blogs",
              limit: this.perPage,
              offset: (this.currentPage - 1) * this.perPage,
              author: this.objCategory.title,
            },
          };
        }
      } else if (this.categorySlider) {
        obj = {
          communityData: {
            communityName: currentCommunityName.communityName,
          },
          Data: {
            type: "blogs",
            limit: this.perPage,
            offset: (this.currentPage - 1) * this.perPage,
            category: this.categorySlider[0].title,
          },
        };
        // console.log(obj);
      }
      // console.log(obj);
      // console.log(this.totalPageBlog, this.currentPage, "kjjhkjh");
      const { data } = await postsRepository.getPostByCategory(obj);
      // console.log(data);
      if (data) {
        this.$vs.loading.close();
      }
      // console.log(data);
      // console.log(this.homeChannelPost.Data);
      await this.$store.commit("post/homeChannelPost", data);
      let payload={
        scroll:true
      }
      console.log(this.scroll)
      await this.$store.commit("post/Scroll_top",payload)

      //  this.$store.commit("post/Scroll_top",payload)
        
      console.log(this.scroll)
    },
  },
  computed: {
    magittUrl() {
      return this.baseUrlMagitt;
    },
    ...mapState("post", [
      "categorySlider",
      "homeChannelPost",
      "objCategory",
      "totalPageBlog",
      "scroll"
    ]),
  },
  // async mounted() {
  //   // await this.getPostByCategory();
  //   // console.log(this.homeChannelPost.Data);
  // },
  beforeMount() {
    this.token = jwt.decode(localStorage.getItem("token"));
  },
};
</script>
