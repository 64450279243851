<template>
  <div class="lg:mt-4 md:mt-4">
    <div class="lg:grid md:grid lg:grid-cols-2 md:grid-cols-2 gap-8">
      <card
        v-for="(item, index) in homeChannelPodcard.Data"
        :key="'item-' + index"
        class="cursor-pointer flex pod-card-h lg:mb- md:mb-0 mb-3"
        @click="handlePodcastDetails(item)"
      >
        <div class="lg:w-1/4 md:w-1/4 w-1/3">
          <img
            :src="therapyCards[0].img"
            alt="img"
            class="w-full object-cover pod-card-h"
          />
        </div>

        <div class="py-4 px-6 bg-f2f2f2 lg:w-3/4 md:w-3/4 w-2/3">
          <h4
            class="text-3d3d3d lineclamp3 font-semibold min-h88px min-fsize22 sm-fsize14px"
            v-html="item.text.substring(0, 20)"
            >
            
          </h4>

          <div class="lg:flex justify-between items-center mt-4">
            <p class="text-9e9e9e font-semibold sm-fsize10px">
              {{ item.Created_by }}
            </p>
            <p class="text-9e9e9e font-semibold sm-fsize10px">
              {{ new Date(item.createdAt).toLocaleString() }}
            </p>
          </div>
        </div>
      </card>
    </div>

    <div class="mt-12" @click="getPostByCategory('pagination')">
      <vs-pagination
        :total="Math.ceil(totalPagePodcard)"
        v-model="currentPage"
      ></vs-pagination>
    </div>
  </div>
</template>
<script>
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const postsRepository = RepositoryFactory.get("posts");
import { mapState } from "vuex";
export default {
  data() {
    return {
      currentx: 2,
      perPage: 6,
      currentPage: 1,
      totalPages: 1,
      therapyCards: [
        {
          id: "01",
          img: require("@/Core/assets/images/home/ecopodcast/ther.png"),
          title:
            "Episode 1: Investing in pharmaceuticals and healthcare stocks now is a good idea. Why?",
          name: "Ajay  Verma ",
          date: "9th September, 2022",
        },
        {
          id: "02",
          img: require("@/Core/assets/images/home/ecopodcast/ther.png"),
          title:
            "Episode 1: Investing in pharmaceuticals and healthcare stocks now is a good idea. Why?",
          name: "Ajay  Verma ",
          date: "9th September, 2022",
        },
        {
          id: "03",
          img: require("@/Core/assets/images/home/ecopodcast/ther.png"),
          title:
            "Episode 1: Investing in pharmaceuticals and healthcare stocks now is a good idea. Why?",
          name: "Ajay  Verma ",
          date: "9th September, 2022",
        },

        {
          id: "04",
          img: require("@/Core/assets/images/home/ecopodcast/ther.png"),
          title:
            "Episode 1: Investing in pharmaceuticals and healthcare stocks now is a good idea. Why?",
          name: "Ajay  Verma ",
          date: "9th September, 2022",
        },
        {
          id: "05",
          img: require("@/Core/assets/images/home/ecopodcast/ther.png"),
          title:
            "Episode 1: Investing in pharmaceuticals and healthcare stocks now is a good idea. Why?",
          name: "Ajay  Verma ",
          date: "9th September, 2022",
        },
        {
          id: "06",
          img: require("@/Core/assets/images/home/ecopodcast/ther.png"),
          title:
            "Episode 1: Investing in pharmaceuticals and healthcare stocks now is a good idea. Why?",
          name: "Ajay  Verma ",
          date: "9th September, 2022",
        },
      ],
    };
  },
  computed: {
    ...mapState("post", ["homeChannelPodcard", "totalPagePodcard","categorySliderPodcast","objCategoryPodcast"]),
  },
  methods: {
    handlePodcastDetails(postData) {
    
      // if(localStorage.getItem("token")){
        let singlePostLink = `${window.document.location.origin}/readers-view?pid=${postData.post_UniqueId}`;
        // window.open(singlePostLink, "_blank");

        let payload = {
          full: singlePostLink,
        };

        this.$vs.loading();

        this.$store
          .dispatch("shortURL/addShortUrl", payload)
          .then(() => {
            this.$vs.loading.close();
            window.open(this.$store.state.shortURL.url.full, "_blank");
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      // }
      // else{
      //   this.$router.push("/pages/customadminlogin").catch(() => {});}
    },
    isAuth(data) {
      if (localStorage.getItem("token")) {
        this.$router.push({ name: "latest-economy" });
        this.$store.commit("post/postDetail", data);
      } else {
        this.$router.push("/pages/customadminlogin").catch(() => {});
      }
    },
    async getPostByCategory(item) {
   
      this.$vs.loading();
      let obj;
      if (item) {
        if (this.categorySliderPodcast[0].type == "category") {
          obj = {
            communityData: {
              communityName: currentCommunityName.communityName,
            },
            Data: {
              type: "podcast",
              limit: this.perPage,
              offset: (this.currentPage - 1) * this.perPage,
              category: this.objCategoryPodcast.title,
            },
          };
        }
        if (this.categorySliderPodcast[0].type == "author") {
          obj = {
            communityData: {
              communityName: currentCommunityName.communityName,
            },
            Data: {
              type: "blogs",
              limit: this.perPage,
              offset: (this.currentPage - 1) * this.perPage,
              author: this.objCategoryPodcast.title,
            },
          };
        }
      } else if (this.categorySlider) {
        obj = {
          communityData: {
            communityName: currentCommunityName.communityName,
          },
          Data: {
            type: "blogs",
            limit: this.perPage,
            offset: (this.currentPage - 1) * this.perPage,
            category: this.categorySliderPodcast[0].title,
          },
        };

      }

      const { data } = await postsRepository.getPostByCategory(obj);
      if (data) {
        this.$vs.loading.close();
      }
      await this.$store.commit("post/homeChannelPodcard", data);
    },
  },
};
</script>
