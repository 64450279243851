<template>
  <div>
    <h2 class="text-dark font-bold min-fsize40 sm-fsize22px">Products</h2>
    <swiper
      :options="swiperOption"
      ref="mySwiper"
      :dir="$vs.rtl ? 'rtl' : 'ltr'"
      :key="$vs.rtl"
      class="lg:mt-10 md:mt-10 lg:mb-0 md:mb-0 mb-20 mt-2 py-4"
    >
      <swiper-slide
        v-for="(item, index) in policyCards"
        :key="'item-' + index"
        class="cursor-pointer shadow-lg"
      >
        <div class="relative">
          <h4 class="text-white font-bold absolute px-6 py-4">
            Kotak Flexicap <br />Fund
          </h4>

          <img
            :src="item.img"
            alt="img"
            class="w-full trade-img-h object-cover"
          />

          <div
            class="absolute w-full px-4 equailty-bottomleft flex justify-between items-center"
          >
            <div class="text-white">
              Equity
              <select class="ml-4 selectoption-p">
                <option value="volvo">Dir-G</option>
                <option value="saab">Dir-G</option>
                <option value="opel">Dir-G</option>
                <option value="audi">Dir-G</option>
              </select>
            </div>
            <div class="flex justify-between items-center">
              <feather-icon
                icon="MaximizeIcon"
                svgClasses="w-4 h-4"
                class="border-white1 rounded-full p-2 text-white mr-3"
              />
              <feather-icon
                svgClasses="w-4 h-4"
                icon="HeartIcon"
                class="border-white1 rounded-full p-2 text-white mr-3"
              />
              <feather-icon
                svgClasses="w-4 h-4"
                icon="Share2Icon"
                class="border-white1 rounded-full p-2 text-white"
              />
            </div>
          </div>
        </div>

        <div class="p-6">
          <p class="text-9e9e9e">Return in CAGR as of Sep 19,2022</p>

          <div class="flex justify-between items-center my-4">
            <div class="flex items-center">
              <h4 class="text-success font-bold">
                <feather-icon icon="ChevronUpIcon" class="mr-2" />16.55%
              </h4>
            </div>

            <select class="ml-4 selectoptiongrey-p">
              <option value="volvo">Since Inception</option>
              <option value="saab">Since Inception</option>
              <option value="opel">Since Inception</option>
              <option value="audi">Since Inception</option>
            </select>
          </div>

          <div class="bordertext-color py-2"></div>

          <div class="mt-6">
            <div class="flex justify-between items-center mb-1">
              <p class="text-9e9e9e font-semibold">Investment</p>
              <p class="text-9e9e9e font-semibold">Very High</p>
            </div>

            <div class="flex justify-between items-center mb-1">
              <p class="text-9e9e9e font-semibold">Theme</p>
              <p class="text-9e9e9e font-semibold flex items-center">
                Risk
                <feather-icon
                  icon="InfoIcon"
                  class="ml-2"
                  svgClasses="w-4 h-4"
                />
              </p>
            </div>

            <div class="flex justify-start items-center mb-1">
              <p class="text-3d3d3d font-bold">Flexicap</p>
            </div>
          </div>

          <div class="flex md:mt-8 lg:mt-8 mt-4">
            <vs-button
              color="primary"
              class="font-semibold uppercase rounded-none mr-6 lg:py-4 md:py-6 w-1/2 btn-padding sm-btn-size"
            >
              KNOW MORE
            </vs-button>
            <vs-button
              class="uppercase rounded-none lg:py-4 md:py-6 font-semibold w-1/2 btn-padding sm-btn-size"
              type="border"
              color="dark"
            >
              INVEST NOW
            </vs-button>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  data() {
    return {
      swiperOption: {
        slidesPerView: 3,
        centeredSlides: false,
        spaceBetween: 25,
        pagination: {
          el: ".swiper-pagination",
          type: "fraction",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 25,
          },
          1240: {
            slidesPerView: 3,
            spaceBetween: 25,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          },
          375: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          },
        },
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
      },
      policyCards: [
        {
          id: "01",
          img: require("@/Core/assets/images/home/thum1.webp"),
          title: "RBI might hike rate by 50 bps, recession ‘very unlikely’",
          name: "Ajay  Verma ",
          date: "9th September, 2022",
        },
        {
          id: "02",
          img: require("@/Core/assets/images/home/thum2.webp"),
          title: "RBI might hike rate by 50 bps, recession ‘very unlikely’",
          name: "Ajay  Verma ",
          date: "9th September, 2022",
        },
        {
          id: "03",
          img: require("@/Core/assets/images/home/thum3.webp"),
          title: "RBI might hike rate by 50 bps, recession ‘very unlikely’",
          name: "Ajay  Verma ",
          date: "9th September, 2022",
        },
      ],
    };
  },
  components: {
    swiper,
    swiperSlide,
  },
};
</script>
