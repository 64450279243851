<template>
  <div id="scrolltop">
    <h2 class="text-dark font-bold min-fsize40 sm-fsize22px "  >Latest Updates</h2>

    <!-- tabs -->

    <div class="lg:mt-10 md:mt-10 mt-2">
      <LatestUpdateSwiper :GroupList="GroupList" />
      <Policy :id="id" />
      <!-- <vs-tabs class="tabs-spacing">
        <vs-tab label="Policy"> </vs-tab>

        <vs-tab label="Economy"> </vs-tab>

        <vs-tab label="Market"> </vs-tab>

        <vs-tab label="CSR"> </vs-tab>
              </vs-tabs>
   -->
    </div>
  </div>
</template>

<script>
import Policy from "./components/Policy";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
import jwt from "jsonwebtoken";
import { mapState } from "vuex";
const groupsRepository = RepositoryFactory.get("groups");
const postsRepository = RepositoryFactory.get("posts");
import LatestUpdateSwiper from "./components/LatestUpdateSwiper";
export default {
  data() {
    return {
      GroupList: [],
      token: null,
      groupPosts: [],
      id: null,
      postsLimit: 6,
      postsOffset: 0,
    };
  },
  components: {
    Policy,
    LatestUpdateSwiper,
  },
  computed: {
    magittUrl() {
      return this.baseUrlMagitt;
    },
    ...mapState("post", [
      "scroll"
    ]),
  },
  watch:{
    scroll(){
      
    if(this.scroll.scroll==true){
      //alert(this.scroll.scroll)
     window.scrollTo(
      {
        top:document.getElementById("scrolltop").offsetTop,
        left:0,
        behavior:"smooth"
      }
     )
      //window.scrollTo(0,0)
    //  let el=document.querySelector(".scrolltop")
    //   console.log("Here", el);
    }


    }
  },
  methods: {
    async getGroupList() {
      var self = this;
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupData: {
          userId: self.token.userID,
        },
      };
      const { data } = await groupsRepository.getGroupsOfUser(obj);

      if (data) {
        data.forEach((element) => {
          element.isUserGroup = true;
          this.GroupList.push(element);
        });
        // self.GroupList=data;
        this.getPublicGroupList();
      }
    },

    async getPublicGroupList() {
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupData: {
          userId: this.token.userID,
        },
      };
      const { data } = await groupsRepository.getPublicGroups(obj);

      if (data) {
        data.forEach((element) => {
          element.isPublicGroup = true;
          this.GroupList.push(element);
        });
        // this.getPrivateGroupList();
        // self.GroupList=data;
      }
    },
    async getPrivateGroupList() {
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupData: {
          userId: this.token.userID,
        },
      };
      const { data } = await groupsRepository.getPrivateGroups(obj);

      if (data) {
        data.forEach((element) => {
          element.isPrivateGroup = true;
          this.GroupList.push(element);
        });
        // self.GroupList=data;
      }
    },
    // async getGroupDetails() {
    //   var self = this;
    //   let obj = {
    //     groupData: {
    //       id: self.$store.state.groupId,
    //     },
    //     communityData: {
    //       communityName: currentCommunityName.communityName,
    //       communityID: "b2146af0-15c3-11ea-b55d-bdde97cb4377",
    //       communitiesType: 1,
    //       communitiesSubType: 0,
    //     },
    //   };

    //   const { data } = await groupsRepository.getGroup(obj);

    //   if (data) {
    //     if (data[0].group_type_id == 3) {
    //       this.isSystemGroup = true;
    //     } else {
    //       this.isSystemGroup = false;
    //     }
    //     this.$store.state.groupId = data[0].groupId;
    //     this.$store.dispatch("Group/storeGroupId", data[0].groupId);
    //     this.currentGroupId = data[0].groupId;
    //     this.currentGroupName = data[0].group_name;
    //     this.currentGroupIcon = data[0].group_icon;
    //     this.currentGroupDescription = data[0].group_description;
    //     this.currentGroupPostingType = data[0].group_all_time_group;
    //     if (data[0].group_type_id == 1) {
    //       this.currentGroupType = true;
    //     } else {
    //       this.currentGroupType = false;
    //     }
    //     if (data[0].group_type_id == null) {
    //       this.isUniverseGroup = true;
    //     } else {
    //       this.isUniverseGroup = false;
    //     }
    //     // if (data[0].group_type_id == 2) {
    //     //   this.getUsersInPublicGroup();
    //     // } else {

    //     // }

    //     // this.$refs.infiniteLoading.stateChanger.reset();
    //     // setTimeout(() => {
    //     //   this.activate_gr =
    //     //     this.GroupList.findIndex(x => x.groupId == this.currentGroupId) + 1;
    //     // }, 100);
    //   }
    // },
    handleId(id) {
      this.id = id;
    },
    async getPostsOfGroup() {
      let obj = {
        userData: {
          id: this.token.userID,
          uuid: this.token.uuid,
        },
        groupData: {
          id: this.GroupList[0].groupId,
          // id: this.$store.state.groupId,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        paging: {
          limit: this.postsLimit,
          offset: this.postsOffset,
        },
      };

      const { data } = await postsRepository.getPostsOfGroup(obj);

      await this.$store.commit("post/homeChannelPost", data);

      data.map((x) => {
        if (x.image) {
          this.imagePost.push(x);
        }
      });

      // return new Promise((resolve) => {
      //   if (data) {
      //     this.postsPage += 1;
      //     this.postsOffset += this.postsLimit;

      //     if (data.length > 0) {
      //       data.forEach((element) => {
      //         // if (
      //         //   moment().diff(
      //         //     moment(element.post_group_mapping_created_datetimestamp),
      //         //     "hours"
      //         //   ) < 13
      //         // ) {
      //         // element.post_group_mapping_created_datetimestamp = moment(
      //         //   element.post_group_mapping_created_datetimestamp
      //         // ).fromNow();
      //         // } else {
      //         //   element.post_group_mapping_created_datetimestamp = moment(
      //         //     element.post_group_mapping_created_datetimestamp
      //         //   ).format("DD-MM-YYYY HH:mm");
      //         // }
      //         if (element.image != null) {
      //           if (element.image.includes("uploads/postImages")) {
      //             element.image = this.magittUrl + element.image;
      //           }
      //         }
      //         if (element.video_url != null) {
      //           if (element.video_url.includes("uploads/postVideos")) {
      //             element.video_url = this.magittUrl + element.video_url;
      //           }
      //         }
      //         if (element.audio_url != null) {
      //           if (element.audio_url.includes("uploads/postAudios")) {
      //             element.audio_url = this.magittUrl + element.audio_url;
      //           }
      //         }
      //         if (element.featured_image != null) {
      //           if (element.featured_image.includes("uploads/postImages")) {
      //             element.featured_image =
      //               this.magittUrl + element.featured_image;
      //           }
      //         }
      //         if (element.PollQuestion !== null) {
      //           element.VotedIds =
      //             element.VotedIds !== null ? element.VotedIds : "";
      //           element.AnswersIds =
      //             element.AnswersIds !== null
      //               ? element.AnswersIds.split(",")
      //               : [];
      //           element.PollAnswers =
      //             element.PollAnswers !== null
      //               ? element.PollAnswers.split(",")
      //               : [];
      //           element.AnswersVotes =
      //             element.AnswersVotes !== null
      //               ? element.AnswersVotes.split(",")
      //               : [];
      //         }
      //         element.comments = [];
      //         element.newComment = "";
      //         element.showReply = false;
      //         element.first_comment_replies = [];
      //         element.isLikeLoading = false;
      //         this.groupPosts.push(element);
      //       });

      //       resolve(true);
      //     } else {
      //       //       ////debugger
      //       resolve(false);
      //     }
      //   } else {
      //     resolve(false);
      //   }
      // });
    },
    async getPostByCategory() {
      let obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        Data: {
          type: "blogs",
          limit: this.postsLimit,
          offset: this.postsOffset,
        },
      };
      const { data } = await postsRepository.getPostByCategory(obj);

      await this.$store.commit("post/homeChannelPost", data);
    },
  },
  async mounted() {
    this.token = jwt.decode(localStorage.getItem("token"));
    await this.getGroupList();

    // this.getGroupDetails();
  },
};
</script>
