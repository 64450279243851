<template>
  <div
    class="lg:flex md:flex lg:w-full md:w-full items-center lg:py-1 md:py-1 py-2"
  >
    <div class="w-full">
      <swiper
        :options="swiperOption"
        class="related-product-swiper navoption-set px-10"
      >
        <swiper-slide
          v-for="(item, index) in categoryList"
          :key="'item-' + index"
          class="cust-swiper"
        >
          <h2
            class="text-3d3d3d font-semibold fsize16 sm-fsize12 capitalize ff-mont font-bold"
            @click="getPostByCategory(item)"
          >
            {{ item.title }}
          </h2>
        </swiper-slide>

        <div
          class="swiper-button-prev cust-roundicon mob-cust-swiper"
          slot="button-prev"
        ></div>
        <div
          class="swiper-button-next cust-roundicon mob-cust-swiper"
          slot="button-next"
        ></div>
      </swiper>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";

import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
import jwt from "jsonwebtoken";

// const groupsRepository = RepositoryFactory.get("groups");
const postsRepository = RepositoryFactory.get("posts");

export default {
  props: {
    GroupList: { type: Object, required: true },
  },
  data() {
    return {
      catname: "",
      token: null,
      categoryList: null,
      postsLimit: 30,
      postsOffset: 0,
      perPage: 6,

      currentPage: 1,
      category: [
        {
          id: "01",
          categoryName: "Policy",
        },
        {
          id: "02",
          categoryName: "Economy",
        },
        {
          id: "03",
          categoryName: "Market",
        },
        {
          id: "04",
          categoryName: "CSR",
        },
      ],
      swiperOption: {
        slidesPerView: 6,

        spaceBetween: 10,

        breakpoints: {
          640: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 6,
            spaceBetween: 20,
          },
          575: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          425: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  components: {
    swiper,
    swiperSlide,
  },

  computed: {
    ...mapState("category", {
      category: "category",
      getsubcatbyid: "getsubcatbyid",
    }),
    ...mapState("post", ["categorySlider"]),
  },
  methods: {
    // async getPostsOfGroup(id) {
    //   console.log("jgjghghg");
    //   let obj = {
    //     userData: {
    //       id: this.token.userID,
    //       uuid: this.token.uuid,
    //     },
    //     groupData: {
    //       id: id,
    //       // id: this.$store.state.groupId,
    //     },
    //     communityData: {
    //       communityName: currentCommunityName.communityName,
    //     },
    //     paging: {
    //       limit: this.postsLimit,
    //       offset: this.postsOffset,
    //     },
    //   };

    //   console.log(obj);

    //   const { data } = await postsRepository.getPostsOfGroup(obj);
    //   await this.$store.commit("post/homeChannelPost", data);
    //   console.log(data);
    //   data.map((x) => {
    //     if (x.image) {
    //       this.imagePost.push(x);
    //     }
    //   });
    //   console.log(this.imagePost);
    //   // return new Promise((resolve) => {
    //   //   if (data) {
    //   //     this.postsPage += 1;
    //   //     this.postsOffset += this.postsLimit;

    //   //     if (data.length > 0) {
    //   //       data.forEach((element) => {
    //   //         // if (
    //   //         //   moment().diff(
    //   //         //     moment(element.post_group_mapping_created_datetimestamp),
    //   //         //     "hours"
    //   //         //   ) < 13
    //   //         // ) {
    //   //         // element.post_group_mapping_created_datetimestamp = moment(
    //   //         //   element.post_group_mapping_created_datetimestamp
    //   //         // ).fromNow();
    //   //         // } else {
    //   //         //   element.post_group_mapping_created_datetimestamp = moment(
    //   //         //     element.post_group_mapping_created_datetimestamp
    //   //         //   ).format("DD-MM-YYYY HH:mm");
    //   //         // }
    //   //         if (element.image != null) {
    //   //           if (element.image.includes("uploads/postImages")) {
    //   //             element.image = this.magittUrl + element.image;
    //   //           }
    //   //         }
    //   //         if (element.video_url != null) {
    //   //           if (element.video_url.includes("uploads/postVideos")) {
    //   //             element.video_url = this.magittUrl + element.video_url;
    //   //           }
    //   //         }
    //   //         if (element.audio_url != null) {
    //   //           if (element.audio_url.includes("uploads/postAudios")) {
    //   //             element.audio_url = this.magittUrl + element.audio_url;
    //   //           }
    //   //         }
    //   //         if (element.featured_image != null) {
    //   //           if (element.featured_image.includes("uploads/postImages")) {
    //   //             element.featured_image =
    //   //               this.magittUrl + element.featured_image;
    //   //           }
    //   //         }
    //   //         if (element.PollQuestion !== null) {
    //   //           element.VotedIds =
    //   //             element.VotedIds !== null ? element.VotedIds : "";
    //   //           element.AnswersIds =
    //   //             element.AnswersIds !== null
    //   //               ? element.AnswersIds.split(",")
    //   //               : [];
    //   //           element.PollAnswers =
    //   //             element.PollAnswers !== null
    //   //               ? element.PollAnswers.split(",")
    //   //               : [];
    //   //           element.AnswersVotes =
    //   //             element.AnswersVotes !== null
    //   //               ? element.AnswersVotes.split(",")
    //   //               : [];
    //   //         }
    //   //         element.comments = [];
    //   //         element.newComment = "";
    //   //         element.showReply = false;
    //   //         element.first_comment_replies = [];
    //   //         element.isLikeLoading = false;
    //   //         this.groupPosts.push(element);
    //   //       });

    //   //       resolve(true);
    //   //     } else {
    //   //       //       ////debugger
    //   //       resolve(false);
    //   //     }
    //   //   } else {
    //   //     resolve(false);
    //   //   }
    //   // });
    // },
    async getCategoryByPost() {
      this.$vs.loading();
      let obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        Data: {
          type: "blogs",
        },
      };
      const { data } = await postsRepository.getCategoryByPost(obj);
      if (data) {
        this.$vs.loading.close();
      }
      this.categoryList = data.Data;
      this.$store.commit("post/categorySlider", data.Data);
      // console.log(data);
    },
    async getPostByCategory(item) {
      // console.log(item);
      this.$vs.loading();
      let obj;
      if (item.type == "category") {
        obj = {
          communityData: {
            communityName: currentCommunityName.communityName,
          },
          Data: {
            type: "blogs",
            limit: this.perPage,
            offset: (this.currentPage - 1) * this.perPage,
            category: item.title,
          },
        };
      }
      if (item.type == "author") {
        obj = {
          communityData: {
            communityName: currentCommunityName.communityName,
          },
          Data: {
            type: "blogs",
            limit: this.perPage,
            offset: (this.currentPage - 1) * this.perPage,
            author: item.title,
          },
        };
      }
      // console.log(item);
      this.$store.commit("post/objCommit", item);
      // console.log(obj);
      const { data } = await postsRepository.getPostByCategory(obj);
      if (data) {
        this.$vs.loading.close();
      }
      // console.log(data);
      await this.$store.commit("post/homeChannelPost", data);
    },
    async getPostByCategoryMount() {
      let obj;
      obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        Data: {
          type: "blogs",
          limit: this.perPage,
          offset: (this.currentPage - 1) * this.perPage,
          category: this.categorySlider[0].title,
        },
      };
      const { data } = await postsRepository.getPostByCategory(obj);
      await this.$store.commit("post/objCommit", this.categorySlider[0]);
      await this.$store.commit("post/homeChannelPost", data);
    },
  },
  async mounted() {
    this.token = jwt.decode(localStorage.getItem("token"));
    // await this.getGroupList();
    // await this.getPostsOfGroup();
    await this.getCategoryByPost();
    await this.getPostByCategoryMount();
  },
};
</script>
<style lang="scss">
.cust-roundicon {
  box-shadow: 0 2px 12px 0 #0000001a;
  width: 40px;
  height: 40px;
  border-radius: 999px;
  color: #e74747;
}
.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 14px 44px;
  background-position: center;
  background-repeat: no-repeat;
  padding: 22px;
}
.swiper-wrapper {
  padding: 0px 30px !important;
  @media (max-width: 640px) {
    padding: 0px !important;
  }
}
.swiper-button-next:after,
.cust-roundicon:after {
  font-size: 20px !important;
}
.swiper-button-next:after,
.cust-roundicon:after {
  font-size: 20px !important;
}
.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 14px 44px;
  background-position: center;
  background-repeat: no-repeat;
  padding: 22px;
}
.navoption-set {
  @media (min-width: 641px) {
    padding: 30px !important;
  }

  @media (max-width: 640px) {
    padding: 10px 0px !important;
  }
}
.navoption-set .swiper-wrapper {
  align-items: center;
}
</style>
