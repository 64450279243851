<template>
  <div
    class="lg:flex md:flex lg:w-full md:w-full items-center lg:py-1 md:py-1 py-2"
  >
    <div class="w-full">
      <swiper
        :options="swiperOption"
        class="related-product-swiper navoption-set px-10"
      >
        <swiper-slide
          v-for="(item, index) in categoryList"
          :key="'item-' + index"
          class="cust-swiper"
        >
          <h2
            class="text-3d3d3d font-semibold fsize16 sm-fsize12 capitalize ff-mont font-bold"
            @click="getPostByCategory(item)"
          >
            {{ item.title ? item.title : "title" }}
          </h2>
        </swiper-slide>

        <div
          class="swiper-button-prev cust-roundicon mob-cust-swiper"
          slot="button-prev"
        ></div>
        <div
          class="swiper-button-next cust-roundicon mob-cust-swiper"
          slot="button-next"
        ></div>
      </swiper>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
import jwt from "jsonwebtoken";

const groupsRepository = RepositoryFactory.get("groups");
const postsRepository = RepositoryFactory.get("posts");

export default {
  props: {
    GroupList: { type: Object, required: true },
  },
  data() {
    return {
      catname: "",
      token: null,
      categoryList: null,
      postsLimit: 100,
      postsOffset: 0,
      category: [
        {
          id: "01",
          categoryName: "Investor Therapy",
        },
        {
          id: "02",
          categoryName: "International Markets",
        },
        {
          id: "03",
          categoryName: "Indian Market",
        },
        {
          id: "04",
          categoryName: "Kotak Katha",
        },
        {
          id: "05",
          categoryName: "CSR",
        },
      ],
      swiperOption: {
        slidesPerView: 6,

        spaceBetween: 10,

        breakpoints: {
          640: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 6,
            spaceBetween: 20,
          },
          575: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          425: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  components: {
    swiper,
    swiperSlide,
  },

  computed: {
    ...mapState("category", {
      category: "category",
      getsubcatbyid: "getsubcatbyid",
    }),
    ...mapState("post", ["categorySliderPodcast"]),
  },
  methods: {
    async getPostByCategory(item) {
      this.$vs.loading();
      let obj;
      if (item.type == "category") {
        obj = {
          communityData: {
            communityName: currentCommunityName.communityName,
          },
          Data: {
            type: "podcast",
            limit: this.perPage,
            offset: (this.currentPage - 1) * this.perPage,
            category: item.title,
          },
        };
      }
      if (item.type == "author") {
        obj = {
          communityData: {
            communityName: currentCommunityName.communityName,
          },
          Data: {
            type: "podcast",
            limit: this.perPage,
            offset: (this.currentPage - 1) * this.perPage,
            author: item.title,
          },
        };
      }
      this.$store.commit("post/objCommitPodcast", item);
      const { data } = await postsRepository.getPostByCategory(obj);
      if (data) {
        this.$vs.loading.close();
      }
      await this.$store.commit("post/homeChannelPodcard", data);
    },
    async getPostByCategoryMount() {
      let obj;
      obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        Data: {
          type: "podcast",
          limit: this.perPage,
          offset: (this.currentPage - 1) * this.perPage,
          category: this.categorySliderPodcast[0].title,
        },
      };
      const { data } = await postsRepository.getPostByCategory(obj);

      await this.$store.commit(
        "post/objCommitPodcast",
        this.categorySliderPodcast[0]
      );

      await this.$store.commit("post/homeChannelPodcard", data);
    },
    async getCategoryByPost() {
      let obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        Data: {
          type: "podcast",
        },
      };
      const { data } = await postsRepository.getCategoryByPost(obj);
      this.categoryList = data.Data;
      this.$store.commit("post/categorySliderPodcast", data.Data);
    },
  },
  async mounted() {
    this.token = jwt.decode(localStorage.getItem("token"));
    await this.getCategoryByPost();
    await this.getPostByCategoryMount();
    // await this.getPostByCategory();
    // this.getGroupDetails();
  },
};
</script>
<style lang="scss">
.cust-roundicon {
  box-shadow: 0 2px 12px 0 #0000001a;
  width: 40px;
  height: 40px;
  border-radius: 999px;
  color: #e74747;
}
.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 14px 44px;
  background-position: center;
  background-repeat: no-repeat;
  padding: 22px;
}
.swiper-wrapper {
  padding: 0px 30px !important;
  @media (max-width: 640px) {
    padding: 0px !important;
  }
}
.swiper-button-next:after,
.cust-roundicon:after {
  font-size: 20px !important;
}
.swiper-button-next:after,
.cust-roundicon:after {
  font-size: 20px !important;
}
.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 14px 44px;
  background-position: center;
  background-repeat: no-repeat;
  padding: 22px;
}
.navoption-set {
  @media (min-width: 641px) {
    padding: 30px !important;
  }

  @media (max-width: 640px) {
    padding: 10px 0px !important;
  }
}
.navoption-set .swiper-wrapper {
  align-items: center;
}
</style>
